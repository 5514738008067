import React, { useContext, useState, useEffect, useRef } from "react";
import { DeSoIdentityContext } from "react-deso-protocol";
import {
  getNFTsForUser,
  transferNFT,
  identity,
  getHodlersForUser,
} from "deso-protocol";
import { getDisplayName } from "../helpers";
import NFTTransferCard from "components/NFTTransferCard";
import NFTPurchaseCard from "components/NFTPurchaseCard";
import axios from "axios";
import HeroSection from "components/HeroSection";
import { Loader2 } from "lucide-react";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogAction,
} from "components/ui/alert-dialog"; // Replace with correct import from your UI library
import BigNumber from "bignumber.js";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { motion } from "framer-motion";

export const HomeTwo = () => {
  const { currentUser, isLoading } = useContext(DeSoIdentityContext);
  const [userNFTs, setUserNFTs] = useState({});
  const [selectedNFTs, setSelectedNFTs] = useState([]);
  const [loading, setLoading] = useState(false); // For showing the loader
  const [alertTitle, setAlertTitle] = useState(""); // Title for the alert
  const [alertMessage, setAlertMessage] = useState(""); // For showing success/error messages
  const [alertLoadingMessage, setAlertLoadingMessage] = useState(""); // For showing loading message in alert
  const [isDialogOpen, setIsDialogOpen] = useState(false); // Control alert visibility
  const [isSubmitting, setIsSubmitting] = useState(false); // For showing the loader inside dialog
  const [tokensHeld, setTokensHeld] = useState(0);
  const [tokensHeldNumber, setTokensHeldNumber] = useState(0);
  const [tokensFetched, setTokensFetched] = useState(false); // For showing the loader inside dialog
  const [sharkTokens, setSharkTokens] = useState(0);
  const [sharkTokensFetched, setSharkTokensFetched] = useState(false); // For showing the loader inside dialog
  const [selectedTab, setSelectedTab] = useState("receivetokens");

  const cardRef = useRef(null); // Used for any additional reference

  useEffect(() => {
    setTokensFetched(false);
    setSharkTokensFetched(false);
    const fetchUserNFTs = async () => {
      if (!isLoading && currentUser) {
        try {
          const testNFTs = await getNFTsForUser({
            UserPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
          });
          if (!testNFTs || Object.keys(testNFTs.NFTsMap).length === 0) {
            setUserNFTs(null);
          } else {
            setUserNFTs(testNFTs);
          }
          setTokensHeld(0);
          setTokensHeldNumber(0);
          getTokenCount();
          setSharkTokens(0);
          getSharkTokenCount();
        } catch (error) {
          setUserNFTs(null);
        }
      }
    };

    fetchUserNFTs();
  }, [isLoading, currentUser]);

  useEffect(() => {
    setSharkTokens(0);
    getSharkTokenCount();
    const storedTab = localStorage.getItem("selectedTab");
    if (storedTab) {
      setSelectedTab(storedTab);
    }
  }, []);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleTransferNFTs = async (event) => {
    if (event) event.preventDefault();
    if (!currentUser) {
      console.error("User not logged in");
      return;
    }

    const senderPublicKey = currentUser.PublicKeyBase58Check;
    const receiverPublicKey = process.env.REACT_APP_SWAP_PUBLIC_KEY;
    const transferDataArray = [];

    // Build NFTOperationLimitMap dynamically based on selected NFTs
    let nftLimitMap = {};
    selectedNFTs.forEach((nft) => {
      if (!nftLimitMap[nft.NFTPostHashHex]) {
        nftLimitMap[nft.NFTPostHashHex] = {};
      }
      nftLimitMap[nft.NFTPostHashHex][nft.SerialNumber] = {
        transfer: 5, // Allow one transfer for the specific serial number
      };
    });
    try {
      if (
        !identity.hasPermissions({
          GlobalDESOLimit: 10000000, // 0.01 DESO
          NFTOperationLimitMap: nftLimitMap,
        })
      ) {
        await identity.requestPermissions({
          GlobalDESOLimit: 10000000,
          NFTOperationLimitMap: nftLimitMap,
        });
      }
    } catch (error) {
      console.error("Error requesting permissions:", error);
      return;
    }

    setIsDialogOpen(true); // Open the dialog
    setAlertTitle("NFT Transfer");
    setAlertLoadingMessage("Transferring NFTs...");
    setIsSubmitting(true); // Start the submitting/loading state
    await sleep(3000); // Pause for 1 second (1000 milliseconds)

    const desoIdentityUsersStr = localStorage.getItem("desoIdentityUsers");
    const desoIdentityUsers = JSON.parse(desoIdentityUsersStr);
    const currentUserInfo = desoIdentityUsers[currentUser.PublicKeyBase58Check];
    const derivedSeedHex = currentUserInfo.primaryDerivedKey.derivedSeedHex;

    // Proceed with the NFT transfer
    for (const nft of selectedNFTs) {
      try {
        const response = await transferNFT(
          {
            SenderPublicKeyBase58Check: senderPublicKey,
            ReceiverPublicKeyBase58Check: receiverPublicKey,
            NFTPostHashHex: nft.NFTPostHashHex,
            SerialNumber: nft.SerialNumber,
            MinFeeRateNanosPerKB: 1700,
          },
          { checkPermissions: false }
        );

        console.log("NFT Transfer response:", response);

        const transferData = {
          publicKey: senderPublicKey,
          username: getDisplayName(currentUser),
          transferHash: response.TransactionHex,
          nftHash: nft.NFTPostHashHex,
          nftSerial: nft.SerialNumber,
        };

        transferDataArray.push(transferData);
      } catch (error) {
        console.error(
          "Error transferring NFT:",
          error.response ? error.response.data : error.message
        );

        // Display the error message in the AlertDialog
        setAlertMessage(
          `Error during NFT transfer: ${
            error.response ? error.response.data.message : error.message
          }`
        );
        setIsSubmitting(false); // Stop the loading state
        return; // Exit the loop on error
      }
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_RECEIVE_NFT,
        transferDataArray
      );

      // Check if the statusCode in the response is 200 (success case)
      if (response.data && response.data.statusCode === 200) {
        if (response.data.tokens) {
          setAlertMessage(
            `NFT transfer successful! ${response.data.tokens} tokens have been transferred to your account.`
          );
        } else {
          setAlertMessage("NFT transfer successful!");
        }

        console.log(response);
      } else {
        // Handle cases where the response status is not 200 (error returned from server)
        setAlertMessage(
          `Error: ${response.data.error || "Unknown error occurred."}`
        );
      }

      console.log(response);
    } catch (error) {
      console.log(error);

      // Check if there is a specific error message in the response
      if (error.response && error.response.data && error.response.data.error) {
        setAlertMessage(`Error: ${error.response.data.error}`);
      } else {
        setAlertMessage("Error during NFT transfer. Please try again.");
      }
    } finally {
      setIsSubmitting(false); // Stop the loader inside dialog
      setSelectedNFTs([]); // Clear selected NFTs after transfer
    }
  };

  const handlePurchaseRandomNFT = async (event) => {
    if (event) event.preventDefault();
    if (!currentUser) {
      console.error("User not logged in");
      return;
    }

    const senderPublicKey = currentUser.PublicKeyBase58Check;
    const tokenPublicKey = process.env.REACT_APP_SWAP_PUBLIC_KEY;
    const transferDataArray = [];

    try {
      if (
        !identity.hasPermissions({
          GlobalDESOLimit: 10000000, // 0.01 DESO
          DAOCoinOperationLimitMap: {
            [tokenPublicKey]: {
              transfer: 1000,
            },
          },
        })
      ) {
        await identity.requestPermissions({
          GlobalDESOLimit: 10000000,
          DAOCoinOperationLimitMap: {
            [tokenPublicKey]: {
              transfer: 1000,
            },
          },
        });
      }
    } catch (error) {
      console.error("Error requesting permissions:", error);
      return;
    }

    setIsDialogOpen(true); // Open the dialog
    setAlertTitle("Swapping Tokens for NFT");
    setAlertLoadingMessage("Transferring NFT...");
    setIsSubmitting(true); // Start the submitting/loading state
    await sleep(3000); // Pause for 1 second (1000 milliseconds)

    const desoIdentityUsersStr = localStorage.getItem("desoIdentityUsers");
    const desoIdentityUsers = JSON.parse(desoIdentityUsersStr);
    const currentUserInfo = desoIdentityUsers[currentUser.PublicKeyBase58Check];
    const derivedSeed = currentUserInfo.primaryDerivedKey.derivedSeedHex;
    const derivedPublicKey =
      currentUserInfo.primaryDerivedKey.derivedPublicKeyBase58Check;

    const transferData = {
      publicKey: senderPublicKey,
      username: getDisplayName(currentUser),
      derivedSeed: derivedSeed,
      derivedPublicKey: derivedPublicKey,
    };

    transferDataArray.push(transferData);

    try {
      const response = await axios.post(
        process.env.REACT_APP_RECEIVE_TOKENS,
        transferDataArray
      );

      // Check if the statusCode in the response is 200 (success case)
      if (response.data && response.data.statusCode === 200) {
        if (response.data.nftImageURL) {
          setAlertMessage(
            <>
              You've received this SharkGang NFT! <br />
              <span>{response.data.nftBody}</span>
              <a
                href={`https://nftz.me/nft/${response.data.nftHash}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={response.data.nftImageURL}
                  alt="NFT Image"
                  className="rounded-lg mt-4"
                />
              </a>
            </>
          );
        } else {
          setAlertMessage("You've received a SharkGang NFT!");
        }

        console.log(response);
      } else {
        // Handle cases where the response status is not 200 (error returned from server)
        setAlertMessage(
          `Error: ${response.data.error || "Unknown error occurred."}`
        );
      }

      console.log(response);
    } catch (error) {
      console.log(error);

      // Check if there is a specific error message in the response
      if (error.response && error.response.data && error.response.data.error) {
        setAlertMessage(`Error: ${error.response.data.error}`);
      } else {
        setAlertMessage("Error during NFT transfer. Please try again.");
      }
    } finally {
      setIsSubmitting(false); // Stop the loader inside dialog
      setSelectedNFTs([]); // Clear selected NFTs after transfer
    }
  };

  const handleOkClick = () => {
    setIsDialogOpen(false); // Close the dialog when the OK button is clicked
    window.location.reload(); // Reload the page
  };

  const handleSelectNFT = (nftPostHashHex, serialNumber, imageURL) => {
    setSelectedNFTs((prevSelectedNFTs) => {
      const isSelected = prevSelectedNFTs.some(
        (nft) =>
          nft.NFTPostHashHex === nftPostHashHex &&
          nft.SerialNumber === serialNumber
      );
      if (isSelected) {
        return prevSelectedNFTs.filter(
          (nft) =>
            nft.NFTPostHashHex !== nftPostHashHex ||
            nft.SerialNumber !== serialNumber
        );
      } else {
        return [
          ...prevSelectedNFTs,
          {
            NFTPostHashHex: nftPostHashHex,
            SerialNumber: serialNumber,
            imageURL,
          },
        ];
      }
    });
  };

  function formatNumberCompact(number) {
    if (number < 1000) {
      return number; // Return the number as-is if less than 1000
    } else if (number < 1000000) {
      return (number / 1000).toFixed(1) + "k"; // Convert to thousands with one decimal place
    } else if (number < 1000000000) {
      return (number / 1000000).toFixed(1) + "M"; // Convert to millions with one decimal place
    } else {
      return (number / 1000000000).toFixed(1) + "B"; // Convert to billions with one decimal place
    }
  }

  async function getTokenCount() {
    if (!currentUser) {
      return; // Exit the function if currentUser is null
    }
    let tokenHolders = await getHodlersForUser({
      Username: "",
      FetchAll: true,
      FetchHodlings: true,
      PublicKeyBase58Check: currentUser.PublicKeyBase58Check,
      IsDAOCoin: true,
    });

    // Ensure tokenHolders is not undefined or null
    if (tokenHolders && tokenHolders.Hodlers) {
      const { Hodlers } = tokenHolders;

      // Find the hodler by public key
      const hodler = Hodlers.find(
        (h) =>
          h.CreatorPublicKeyBase58Check ===
          process.env.REACT_APP_TOKEN_PUBLIC_KEY
      );

      // Update the state based on the found hodler
      if (hodler) {
        // Directly use the updated state value
        const balanceNanos = new BigNumber(hodler.BalanceNanosUint256); // BalanceNanos from your JSON
        const tokens = balanceNanos.dividedBy(new BigNumber("1e18"));
        const formattedTokens = formatNumberCompact(Number(tokens.toFixed(0)));
        setTokensHeld(formattedTokens.toString()); // Ensure it's a string
        setTokensHeldNumber(tokens.toFixed(0).toString()); // Convert to string
        setTokensFetched(true);
      } else {
        setTokensHeld(0);
        setTokensHeldNumber(0);
        setTokensFetched(true);
      }
    } else {
      console.log("No token holders data found");
      setTokensHeld(0);
      setTokensHeldNumber(0);
      setTokensFetched(true);
    }
  }

  async function getSharkTokenCount() {
    let tokenHolders = await getHodlersForUser({
      Username: "",
      FetchAll: true,
      FetchHodlings: true,
      PublicKeyBase58Check: process.env.REACT_APP_SWAP_PUBLIC_KEY,
      IsDAOCoin: true,
    });

    // Ensure tokenHolders is not undefined or null
    if (tokenHolders && tokenHolders.Hodlers) {
      const { Hodlers } = tokenHolders;

      // Find the hodler by public key
      const hodler = Hodlers.find(
        (h) =>
          h.CreatorPublicKeyBase58Check ===
          process.env.REACT_APP_TOKEN_PUBLIC_KEY
      );

      // Update the state based on the found hodler
      if (hodler) {
        // Directly use the updated state value
        const balanceNanos = new BigNumber(hodler.BalanceNanosUint256); // BalanceNanos from your JSON
        const tokens = balanceNanos.dividedBy(new BigNumber("1e18"));
        const formattedTokens = formatNumberCompact(Number(tokens.toFixed(0)));
        //setSharkTokensHeld(formattedTokens.toString()); // Ensure it's a string
        setSharkTokens(parseInt(tokens.toFixed(0), 10));
        setSharkTokensFetched(true);
      } else {
        setSharkTokens(0);
        setSharkTokensFetched(true);
      }
    } else {
      console.log("No token holders data found");
      setSharkTokens(0);
      setSharkTokensFetched(true);
    }
  }

  // Handle tab change and store the new tab value in localStorage
  const handleTabChange = (value) => {
    setSelectedTab(value);
    localStorage.setItem("selectedTab", value);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-black">
        <Loader2 className="animate-spin text-white w-12 h-12" />
        <span className="text-white text-2xl">Loading...</span>
      </div>
    );
  }

  const filterKey = process.env.REACT_APP_MINTER_PUBLIC_KEY;

  const tabVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
    exit: { opacity: 0, y: 10, transition: { duration: 0.2 } },
  };

  const cardVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1, transition: { duration: 0.4 } },
    exit: { opacity: 0, scale: 0.95, transition: { duration: 0.2 } },
  };

  return (
    <div className="bg-page-background">
      <HeroSection
        currentUser={currentUser}
        tokensHeld={tokensHeld}
        tokensFetched={tokensFetched}
      />

      <div className="flex flex-col md:flex-row items-start justify-center gap-0 py-0 px-4">
        <div className="relative w-full">
          <Tabs
            value={selectedTab}
            onValueChange={handleTabChange}
            className="w-full"
          >
            <div className="relative flex flex-col gap-4 items-center justify-center py-4 px-4 md:px-2 w-full h-svh">
              {/* Wrap TabsTrigger with motion.div for animation */}
              <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={tabVariants}
                className="absolute -top-7 z-10"
              >
                <TabsList>
                  <TabsTrigger value="receivetokens">
                    Swap Tokens for NFT
                  </TabsTrigger>
                  <TabsTrigger value="receiveNFT">
                    Swap NFT for Tokens
                  </TabsTrigger>
                </TabsList>
              </motion.div>

              <div className="relative w-full h-full">
                {" "}
                {/* Create a container with relative positioning */}
                <motion.div
                  key={selectedTab}
                  variants={cardVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  className="w-full h-full"
                >
                  {selectedTab === "receivetokens" && (
                    <NFTTransferCard
                      currentUser={currentUser}
                      selectedNFTs={selectedNFTs}
                      handleTransferNFTs={handleTransferNFTs}
                      userNFTs={userNFTs}
                      handleSelectNFT={handleSelectNFT}
                      sharkSwapTokenBalance={sharkTokens}
                      sharkTokensFetched={sharkTokensFetched}
                      filterKey={filterKey}
                    />
                  )}

                  {selectedTab === "receiveNFT" && (
                    <NFTPurchaseCard
                      currentUser={currentUser}
                      handlePurchaseRandomNFT={handlePurchaseRandomNFT}
                      tokensHeld={tokensHeld}
                      tokensHeldNumber={tokensHeldNumber}
                      tokensFetched={tokensFetched}
                    />
                  )}
                </motion.div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>

      {/* Alert Dialog remains unchanged */}
      <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <AlertDialogContent className="bg-[#001f3f] text-white rounded-lg shadow-lg border-black">
          <AlertDialogHeader>
            <div ref={cardRef}></div>
            <AlertDialogTitle>{alertTitle}</AlertDialogTitle>
            <AlertDialogDescription>
              {isSubmitting ? (
                <div className="flex items-center">
                  <Loader2 className="mr-2 animate-spin" size={24} />
                  {alertLoadingMessage}
                </div>
              ) : (
                <div>{alertMessage}</div>
              )}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            {!isSubmitting && (
              <AlertDialogAction onClick={handleOkClick}>OK</AlertDialogAction>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default HomeTwo;
